import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Layout from '../../components/layout'
import Header from '../../components/header'
import Container from '../../components/container'
import StoryIntro from '../../components/story-intro'
import Row from '../../components/row'
import Column from '../../components/column'

SwiperCore.use([Navigation, Pagination])

const PageHeader = <Header title="Touch Steering Wheel" />

const TouchLenkradPage = ({ location }) => {
    const images = useStaticQuery(graphql`
      {
        demonstrator: allFile(filter: {relativeDirectory: {eq: "stories/touch-lenkrad/demonstrator"}}, sort: {fields: name}) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                width: 1050
                height: 650
                transformOptions: {
                  cropFocus: CENTER
                }
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            name
          }
        }
        iddrei: allFile(filter: {relativeDirectory: {eq: "stories/touch-lenkrad/id"}}, sort: {fields: name}) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                width: 1050
                height: 650
                transformOptions: {
                  cropFocus: CENTER
                }
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            name
          }
        }
      }
    `)

    return (
        <Layout translatedPageLinks={[{ code: 'de', link: '/de/story/multifunktions-touchlenkrad/' }, { code: 'en', link: '/en/story/touch-steering-wheel/' }]} pageLang="de" pageTitle="Touch Steering Wheel" pageHeader={PageHeader} location={location}>
            <Container>
                <section>
                    <StoryIntro>
                        <p>
                          When it comes to innovations, GE-T has a firm grip on the steering wheel. 
                          The idea and concept for the haptic feedback of the steering wheel of the ID. family, and other premium models of the Volkswagen brand, come from us.
                        </p>
                    </StoryIntro>
                    <p>
                        GE-T guided the development of the multifunctional steering wheel with haptic feedback from idea to various prototypes to the current series product. 
                        The direct feedback to the user enables safe blind operation and is the foundation for application in automobiles. 
                        Together with Volkswagen and Audi, GE-T holds the corresponding patent.
                    </p>
                    <p>
                        GE-T developed the first touch surface with haptic feedback on a steering wheel back in 2016 for the Volkswagen study of the Budd-e, a role model for the upcoming ID.Buzz. 
                        But the development of modern steering wheels won't stop there.
                    </p>
                    <Row>
                        <Column columnWidth={2}>
                            {/* eslint-disable-next-line */}
                            <GatsbyImage image={getImage(images.demonstrator.nodes[1])} alt="" />
                        </Column>
                        <Column columnWidth={2}>
                            {/* eslint-disable-next-line */}
                            <GatsbyImage image={getImage(images.demonstrator.nodes[0])} alt="" />
                        </Column>
                    </Row>
                    <p>
                        Our vision for the "Steering Wheel 2030" doesn't just leave behind the traditional shape of a wheel. 
                        Drive-by-wire technology enables a modern form of steering that is ideally suited to the challenges of autonomous driving. 
                        Pulse sensor technology and vegan materials complement the innovative approach. Hands-on detection is also part of GE-T's ongoing research into steering wheel development. 
                        We have implemented the legislative requirement of hands-on detection in a particularly customer-friendly way. 
                        Moreover, our solution is now not only 20 percent cheaper than previous products, but also functions as a steering wheel heater simultaneously.
                    </p>
                    <Swiper
                        navigation
                        loop
                        pagination={{ clickable: true }}
                    >
                        {/* eslint-disable-next-line */}
                        {images.iddrei.nodes.map((image) => <SwiperSlide key={image.name}><GatsbyImage image={getImage(image)} alt="" /></SwiperSlide>)}
                    </Swiper>
                </section>
            </Container>
        </Layout>
    )
}

export default TouchLenkradPage